<template>
  <div>
    <div class="initPage">
      <div class="logo" />
      <div class="initPage-title">欢迎进入{{sassname}}供应链平台</div>
      <div class="btn-editPassword chand" @click="handleEditPwd">我要修改密码 <i class="el-icon-right" /></div>
      <div class="readme-doc">
        <el-checkbox v-model="activeAgree" @change="handleAgreeDoc" />我已阅读并同意
        <p><a href="javascript: void(0)" @click="handleOpenDocu('1', '隐私政策')">《隐私政策》</a>、</p>
        <p><a href="javascript: void(0)" @click="handleOpenDocu('2', '企业信息采集及使用授权协议')">《企业信息采集及使用授权协议》</a>、</p>
        <p><a href="javascript: void(0)" @click="handleOpenDocu('3', '平台用户服务协议')">《平台用户服务协议》</a>、</p>
        <p><a href="javascript: void(0)" @click="handleOpenDocu('4', '个人信息采集及使用授权协议')">《个人信息采集及使用授权协议》</a></p>
      </div>
      <div class="active-line">
        <el-button type="primary" :disabled="!activeAgree" size="medium" @click="handleActiveSys">增加企业</el-button>
      </div>
    </div>

    <!--弹出协议-->
    <el-dialog
      :title="agreeTitle"
      :visible.sync="centerDialogVisible"
      width="70%"
      center
    >
      <div class="el-dialog-div">
        <page-yinsizhengce v-if="documnetNo === '1'"></page-yinsizhengce>
        <page-qiYeCaiJi v-if="documnetNo === '2'"></page-qiYeCaiJi>
        <page-pingtaiyonghu v-if="documnetNo === '3'"></page-pingtaiyonghu>
        <page-gerenxinxicaiji v-if="documnetNo === '4'"></page-gerenxinxicaiji>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageYinsizhengce from './componens/yinsizhengce.vue'; // 隐私政策
import pageQiYeCaiJi from './componens/qiyecaiji.vue'; // 企业信息采集及使用授权协议
import pagePingtaiyonghu from './componens/pingtaiyonghu.vue'; // 平台用户服务协议
import pageGerenxinxicaiji from './componens/gerenxinxicaiji.vue'; // 个人信息采集及使用授权协议

export default {
  name: 'InitPage',
  components: { pageYinsizhengce, pageQiYeCaiJi, pagePingtaiyonghu, pageGerenxinxicaiji },
  data() {
    return {
      centerDialogVisible: false,
      activeAgree: false,
      agreeTitle: '',
      documnetNo: '',
      sassname:this.$store.state.user.domain.platformName
    };
  },
  methods: {
    handleEditPwd() {
      this.$router.push({ path: '/settings/staff' });
    },
    handleAgreeDoc() {
    },
    handleOpenDocu(type, title) {
      this.documnetNo = type;
      this.centerDialogVisible = true;
    },
    handleActiveSys() {
      // 点击激活系统调用接口，在激活成功后把接口返回值传出去
      this.$emit('handleChangePageStatus', 1);


    }
  }
};

</script>

<style lang="scss" scoped>
  .initPage {
    position: relative;
    margin: 50px auto 0;
    padding: 30px;
    width: 650px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fafafa;
    .logo {
      position: absolute;
      right: -30px;
      top: -30px;
      width: 100px;
      height: 100px;
      background: url('~@/assets/bg/login-logo.png') 50% 50% no-repeat;
      background-size: 82%;
      box-shadow: 5px 5px 5px rgba(0,0,0,.2);
      border-radius: 100px;
    }
  }
  .initPage-title {
    height: 80px;
    font: 24px/80px "Microsoft YaHei";
    color: #24A3FF;
    text-align: center;
    border-bottom: 1px solid #ccc;
  }
  .btn-editPassword {
    margin: 30px 0 30px 20px;
    font: 18px/100% "Microsoft YaHei";
    color: #ff9413;
  }
  .readme-doc {
    text-indent: 6px;
    a {
      color: #24A3FF;
    }
    p {
      text-indent: 36px;
      margin-top: 10px;
    }
  }
  .active-line {
    margin: 50px auto 0;
    text-align: center;
  }
  .el-dialog-div {
    height: 60vh;
    overflow: auto;
  }
  .chand {
    cursor: pointer;
  }
</style>
