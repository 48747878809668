var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          staticStyle: { height: "200px" },
          attrs: { "tab-position": _vm.tabPosition },
        },
        [
          _vm._l(_vm.sasscompany, function (i) {
            return [
              _c(
                "el-tab-pane",
                { attrs: { label: i.name } },
                [
                  [
                    _c("el-tab-pane", { attrs: { label: "权限管理" } }, [
                      _vm._v("权限管理"),
                    ]),
                    _c("el-tab-pane", { attrs: { label: "角色管理" } }, [
                      _vm._v("角色管理"),
                    ]),
                  ],
                ],
                2
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }