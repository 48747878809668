var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "initPage" }, [
        _c("div", { staticClass: "logo" }),
        _c("div", { staticClass: "initPage-title" }, [
          _vm._v("欢迎进入" + _vm._s(_vm.sassname) + "供应链平台"),
        ]),
        _c(
          "div",
          {
            staticClass: "btn-editPassword chand",
            on: { click: _vm.handleEditPwd },
          },
          [_vm._v("我要修改密码 "), _c("i", { staticClass: "el-icon-right" })]
        ),
        _c(
          "div",
          { staticClass: "readme-doc" },
          [
            _c("el-checkbox", {
              on: { change: _vm.handleAgreeDoc },
              model: {
                value: _vm.activeAgree,
                callback: function ($$v) {
                  _vm.activeAgree = $$v
                },
                expression: "activeAgree",
              },
            }),
            _vm._v("我已阅读并同意\n      "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: { href: "javascript: void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.handleOpenDocu("1", "隐私政策")
                    },
                  },
                },
                [_vm._v("《隐私政策》")]
              ),
              _vm._v("、"),
            ]),
            _c("p", [
              _c(
                "a",
                {
                  attrs: { href: "javascript: void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.handleOpenDocu(
                        "2",
                        "企业信息采集及使用授权协议"
                      )
                    },
                  },
                },
                [_vm._v("《企业信息采集及使用授权协议》")]
              ),
              _vm._v("、"),
            ]),
            _c("p", [
              _c(
                "a",
                {
                  attrs: { href: "javascript: void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.handleOpenDocu("3", "平台用户服务协议")
                    },
                  },
                },
                [_vm._v("《平台用户服务协议》")]
              ),
              _vm._v("、"),
            ]),
            _c("p", [
              _c(
                "a",
                {
                  attrs: { href: "javascript: void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.handleOpenDocu(
                        "4",
                        "个人信息采集及使用授权协议"
                      )
                    },
                  },
                },
                [_vm._v("《个人信息采集及使用授权协议》")]
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "active-line" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  disabled: !_vm.activeAgree,
                  size: "medium",
                },
                on: { click: _vm.handleActiveSys },
              },
              [_vm._v("增加企业")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.agreeTitle,
            visible: _vm.centerDialogVisible,
            width: "70%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "el-dialog-div" },
            [
              _vm.documnetNo === "1" ? _c("page-yinsizhengce") : _vm._e(),
              _vm.documnetNo === "2" ? _c("page-qiYeCaiJi") : _vm._e(),
              _vm.documnetNo === "3" ? _c("page-pingtaiyonghu") : _vm._e(),
              _vm.documnetNo === "4" ? _c("page-gerenxinxicaiji") : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.centerDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.centerDialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }