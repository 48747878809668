<template>
    <div class="dashboard">
      <!-- 顶部导航 -->
      <header class="header">
        <h1>数据可视化大屏</h1>
        <div class="datetime">14:54:45 | 2021.09.10</div>
      </header>
  
      <!-- 内容区域 -->
      <div class="content">
        <!-- 左侧数据面板 -->
        <aside class="sidebar">
          <el-card class="card">
            <h3>今日新增数据</h3>
            <p>439.00 万元</p>
          </el-card>
          <el-card class="card">
            <h3>平台当前存款</h3>
            <p>439.00 万元</p>
          </el-card>
        </aside>
  
        <!-- 中间数据可视化 -->
        <main class="main">
          <div ref="chart" class="chart"></div>
        </main>
  
        <!-- 右侧数据面板 -->
        <aside class="sidebar">
          <el-card class="card">
            <h3>平台企业数量</h3>
            <p>439</p>
          </el-card>
          <el-card class="card">
            <h3>平台总收入 IRR</h3>
            <div ref="lineChart" class="chart-small"></div>
          </el-card>
        </aside>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      this.initChart()
      this.initLineChart()
    },
    methods: {
      initChart() {
        const chart = this.$echarts.init(this.$refs.chart)
        chart.setOption({
          title: { text: '示例柱状图', textStyle: { color: '#fff' } },
          xAxis: { data: ['A', 'B', 'C', 'D'], axisLabel: { color: '#fff' } },
          yAxis: { axisLabel: { color: '#fff' } },
          series: [{ type: 'bar', data: [120, 200, 150, 80] }]
        })
      },
      initLineChart() {
        const chart = this.$echarts.init(this.$refs.lineChart)
        chart.setOption({
          title: { text: '示例折线图', textStyle: { color: '#fff' } },
          xAxis: { type: 'category', data: ['周一', '周二', '周三'], axisLabel: { color: '#fff' } },
          yAxis: { axisLabel: { color: '#fff' } },
          series: [{ type: 'line', data: [20, 30, 40] }]
        })
      }
    }
  }
  </script>
  
<style scoped>
.dashboard {
  width: 100vw;
  height: 100vh;
  background: #0a0a0a;
  color: white;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #1f1f1f;
}

.content {
  display: flex;
  flex: 1;
  padding: 20px;
}

.sidebar {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.card {
  background: #181818;
  color: white;
  margin-bottom: 20px;
}

.main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart {
  width: 80%;
  height: 400px;
}

.chart-small {
  width: 100%;
  height: 150px;
}
</style>
