<template>
  <section lang="ZH-CN" style="text-justify-trim:punctuation">

    <div class="WordSection1" style="layout-grid:15.6pt">

      <p class="MsoNormal" align="center" style="text-align:center;background:white"><b><span
        style="font-size:18.0pt;font-family:宋体;color:red"
      >{{sassname}}供应链金融服务平台</span></b></p>

      <p class="MsoNormal" align="center" style="text-align:center;background:white"><b><span
        style="font-size:18.0pt;font-family:宋体;color:black"
      >用户服务协议</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >引言</span></p>

      <p
        class="MsoNormal"
        align="left"
        style="text-align:left;text-indent:19.0pt;
background:white"
      ><span style="font-size:9.5pt;font-family:宋体;color:black">欢迎您使用</span><span
        style="font-size:9.5pt;font-family:宋体;color:red"
      >{{sassname}}供应链金融服务平台</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >！</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
        style="font-size:9.5pt;font-family:宋体;color:red"
      >{{sassname}}供应链金融服务平台</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（以下简称</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >“</span><span style="font-size:9.5pt;font-family:宋体;color:black">本平台</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >”</span><span style="font-size:9.5pt;font-family:宋体;color:black">）根据《用户服务协议》（以下简称</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >“</span><span style="font-size:9.5pt;font-family:宋体;color:black">本协议</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >”</span><span style="font-size:9.5pt;font-family:宋体;color:black">）为您提供服务。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >为使用本平台服务，请您务必审慎阅读、充分理解本协议各条款内容，特别是免除或者限制责任的条款、对用户权利进行限制的条款等。限制、免责条款或者其他涉及您重大权益的条款可能以加粗形式提示您重点注意。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权注册、登录或使用本平台服务。您点击</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >“</span><span style="font-size:9.5pt;font-family:宋体;color:black">已阅读并同意协议（具体措辞详见注册页面）</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >”</span><span style="font-size:9.5pt;font-family:宋体;color:black">及进行注册、使用、获取用户账户、登录及使用相关服务的行为或者以其他任何明示方式表示接受本协议的，均视为您已阅读并同意签署本协议。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >如果您因年龄、智力等因素而不具有完全民事行为能力，请在法定监护人（以下简称</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&quot;</span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >监护人</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:
&quot;PingFangSC-Regular&quot;,serif;color:black"
      >&quot;</span><span
        style="font-size:
9.5pt;font-family:宋体;color:black"
      >）的陪同下阅读和签署本协议。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >您有违反本协议的任何行为时，本平台有权根据您违反约定的情形单方采取限制、中止或终止向您提供服务等措施，并有权追究您相关责任。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >一、定义与解释</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >1.1 </span><span style="font-size:9.5pt;font-family:宋体;color:black">用户是指注册、登录、使用本平台服务的个人或组织，在本协议中更多地称为</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >“</span><span style="font-size:9.5pt;font-family:宋体;color:black">您</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >”</span><span style="font-size:9.5pt;font-family:宋体;color:black">。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >1.2 </span><span style="font-size:9.5pt;font-family:宋体;color:black">本平台是向用户提供用户注册、用户登录、实名核验以及与此相关的身份认证技术服务以及与此相关的融资业务等综合性金融服务的平台。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >1.3 </span><span style="font-size:9.5pt;font-family:宋体;color:black">人脸识别验证身份功能是指由公安部</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >“</span><span style="font-size:9.5pt;font-family:宋体;color:black">互联网</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >+”</span><span style="font-size:9.5pt;font-family:宋体;color:black">身份认证平台等机构提供的身份核验数据及技术支持。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >二、协议适用范围</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >2.1 </span><span style="font-size:9.5pt;font-family:宋体;color:black">本协议是本平台制定的关于获取和使用本平台账户，并基于该账号登陆第三方应用（包括第三方网站、系统等，下同）的相关规则。本规则适用于需要注册或使用本平台账户的全部软件或服务，含第三方应用。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >2.2 </span><span style="font-size:9.5pt;font-family:宋体;color:black">本协议项下的登录服务包括三种情况：</span><span
        style="font-size:9.5pt;font-family:宋体;color:red"
      >平台向用户提供的，通过平台注册账户登录使用本平台的服务；</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >平台向用户提供的，通过平台注册账户登录使用第三方应用的服务；平台向用户提供的，通过第三方账户登录使用第三方应用的服务（以下简称本服务）。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >2.3 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您通过本平台提供的账户使用相关的软件和服务时，须同时遵守各项服务包括第三方应用的单独用户协议及隐私条款。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >三、帐号注册及管理</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.1 </span><span style="font-size:9.5pt;font-family:宋体;color:black">账户注册</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.1.1</span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >本平台账户通过手机号码进行注册</span><span
        style="font-size:9.5pt;font-family:
&quot;PingFangSC-Regular&quot;,serif;color:black"
      /><span
        style="font-size:9.5pt;
font-family:宋体;color:black"
      >，自动生成一个账户以及初始账户密码，账户密码由您自行设置</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >/</span><span style="font-size:9.5pt;font-family:宋体;color:black">修改并由您负责保管。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.1.2 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >您在注册及使用本平台服务时，必须向本平台提供真实、准确、完整、合法有效的资料，且有义务维持并及时更新您的资料。其中，根据实名认证方式的不同，个人所需提供的实名认证资料包括但不限于姓名、身份证信息、人脸生物信息等。企业需要提供的实名认证资料包括但不限于统一信用代码信息、企业法定代表人姓名及其身份证信息。若本平台有合理理由怀疑您提供的身份信息及相关资料错误、不实、过时或不完整的，本平台有权限制或停止向您提供部分或全部服务，您同时须承担因此引起的相应责任及后果。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.1.3 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >您不得冒用他人名义申请非本人的用户账户。如有违反，经核实后，本平台或</span><span
        style="font-size:
9.5pt;font-family:宋体;color:red"
      >第三方服务平台</span><span
        style="font-size:9.5pt;
font-family:宋体;color:black"
      >有权删除其账户。对于冒用他人名义申请非本人的用户账户而引起的一切责任，由冒用者承担。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.1.4</span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >您确认并授权，本平台有权要求核对您的有效身份信息，并留存有效必要文件，您应积极配合，否则本平台有权限制或停止向您提供部分或全部服务。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.1.5 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >若您为企业用户，贵企业合并、解散、注销、宣告破产或倒闭，或被吊销营业执照等主体资格终止的，您应于上述情况发生的</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5</span><span style="font-size:9.5pt;font-family:宋体;color:black">个工作日内通过书面形式告知本平台，终止使用您以该企业用户或注册的本平台账号，否则，本平台有权随时注销您的账号而不承担任何违约责任。因您未尽该通知义务给本平台造成损失的，由您负责全部赔偿。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.1.6</span></b><b><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >您同意并确认通过验证方式登录本平台后，其在本平台以及其他第三方平台申请办理的各项业务，均视为您本人行为。</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.2 </span><span style="font-size:9.5pt;font-family:宋体;color:black">账户管理</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.2.1 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >本平台账户的所有权归本平台所有，用户完成申请注册手续后，仅获得账户的使用权，且该使用权仅属于初始申请注册人。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.2.2 </span></b><b><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >您有责任妥善保管注册账户信息及账户密码的安全，且确认只有您本人可以使用您的账号，您需要对您注册账户以及密码下的行为承担法律责任。当在您知悉您的账户已经失密或者可能已经失密时，您应立即通知本平台。在您决定不再使用该账号时，您应向本平台申请注销该账号。您应对因您未能遵守本款约定而发生的任何损失、损毁及其他不利后果负责。</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.2.3 </span></b><b><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >如果您为企业法定代表人，您以个人身份登录且通过实名身份核验后可选择以法定代表人身份办理名下企业的业务事项。您应确认您为该企业法定代表人后方可使用该项功能，如果您非该企业法定代表人，您不得以该企业法定代表人身份登录本平台办理业务，并应及时向本平台反馈相关信息。如因为您假冒法定代表人身份导致第三人利益受损的，由您承担全部责任。</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.2.4 </span></b><b><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >如果您为企业账户且正在使用第三方服务，您了解并确认，您已将身份信息提交给第三方服务提供者进行身份核验，您同意并授权本平台从第三方服务提供者中获取、使用您所提供的身份信息。</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.2.5 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >如果您当前使用的账户并不是您初始申请注册的或者不是通过本平台提供的其他途径获得的，您不得用该号码登录或进行任何操作，并请您在第一时间通知本平台。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.2.6 </span></b><b><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >如您违反相关法律法规、本规则或其他相关协议、规则，本平台有权限制、中止、冻结或终止您对账户的使用，且根据实际情况决定是否恢复您对账户的使用，而由此产生的损失由您自行承担。</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.3 </span><span style="font-size:9.5pt;font-family:宋体;color:black">账户注销</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.3.1 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >在您需要终止使用本平台服务时，您可以依照我们的流程和页面提示申请注销您本人的账户，请您知悉并同意</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >:</span><span style="font-size:9.5pt;font-family:宋体;color:black">本平台支持用户自行在线注销账户，</span><span
        style="font-size:9.5pt;font-family:宋体;color:red"
      >也可通过线下方式进行人工处理。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.3.2 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >账户注销过程中，本平台</span><span
        style="font-size:9.5pt;font-family:宋体;
color:red"
      >将对</span><span style="font-size:9.5pt;font-family:宋体;color:black">您进行不同方式的身份验证。注销时需要您提供账户申请人的手机号码短信验证码、身份证号码等操作。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.3.3 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >您知悉并同意，在您主动注销账户之后，我们将停止为您提供产品或服务。账户注销后，您将无法通过本平台账号使用本平台服务、第三应用或服务，账户注销将影响历史业务办理的回溯。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.3.4</span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >如您注销本平台的账户，本平台有权自行对账号相关内容及信息（包括但不限于个人身份信息、账户核验记录、账户关联记录、业务办理信息）采取相应的处理措施，包括但不限于根据适用法律的要求删除您的用户信息，或使其匿名化处理，且无需就此向用户承担任何责任。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >四、用户个人信息保护</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >4.1 </span><span style="font-size:9.5pt;font-family:宋体;color:black">本平台非常重视对用户个人信息的保护。本平台将按照本协议及《隐私协议》的规定收集、使用、储存和分享您的个人信息。本协议对个人信息保护规定的内容与上述《隐私协议》有相冲突的，及本协议对个人信息保护相关内容未作明确规定的，均应以《隐私协议》的内容为准。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >4.2 </span><span style="font-size:9.5pt;font-family:宋体;color:black">如您认为您的个人信息被本平台超目的使用、或被其他用户侵权，请立即与本平台联系，并按照要求提供相应的证明资料，本平台将及时调查核实处理。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >4.3 </span><span style="font-size:9.5pt;font-family:宋体;color:black">对于本平台会如何收集、使用、存储和保护您的个人信息及您享有何种权利，您还可以阅读《隐私协议》予以进一步了解。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >五、用户行为规范</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.1 </span></b><b><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >您不得因非正常使用服务之目的恶意注册、获取本平台账户。若经过本平台判断，认为您使用的账户是恶意注册、获取的号码，本平台有权对相应账户采取限制、中止或终止使用等措施。</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.2 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您在使用本服务时，须通过本平台提供的技术接口、登录页面及其他合法通道等进行平台</span><span
        style="font-size:9.5pt;font-family:宋体;color:red"
      >或第三方账号和密码输入</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >、个人信息授权等操作；否则，可能会造成的帐号、密码泄漏或其他损失等由您自行承担。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.3 </span><span style="font-size:9.5pt;font-family:宋体;color:red">第三方</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >负责其提供的具体网站、应用或其他服务的合法性、安全性以及真实性，若因任何第三方原因，造成您损失的，由第三方承担全部责任。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.4 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您通过本服务使用平台或</span><span
        style="font-size:9.5pt;font-family:宋体;color:red"
      >第三方账户登录</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >、使用的网站、应用或服务等，应遵循您与第三方之间的协议、约定或第三方相关的规则等。<b>对于第三方应用没有约定或约定不明的事项，第三方应用提供者有权援引本协议的条款进行解决，第三方应用提供者相应享有或承担相关条款中本平台所享有或承担的相对于您的权利或义务，但这些条款不适用于解决本平台与第三方应用提供者之间的关系，亦不使本平台因第三方责任而对您产生责任。</b></span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.5 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您理解并同意：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >本平台在服务中仅提供了登录接口的技术服务，您通过本服务使用平台或</span><span
        style="font-size:9.5pt;font-family:宋体;color:red"
      >第三方账号登录</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >、使用的网站、应用或服务等，均是由第三方提供的。第三方网站、应用或服务等的质量和安全等由第三方独立负责、承担，请您自行进行判断、甄别，并自行承担相关损失、责任。若您因使用第三方网站、应用或服务等产生的任何纠纷，您应与第三方通过协商或其他合法途径解决。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.6 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您在使用平台提供的服务过程中，必须遵循法律法规、规章、规范性文件及政策要求的规定，遵守所有与平台、网络服务有关的网络协议、规定和程序，并不得利用本平台从事以下行为：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >1</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >2</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >3</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）虚构事实、隐瞒真相以误导、欺骗他人；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >4</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）发表、传送、传播广告信息及垃圾信息；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >5</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）</span><span
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      /><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >利用平台传输、发布违反国家法律法规政策的任何内容（信息）、涉及国家秘密和</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >/</span><span style="font-size:9.5pt;font-family:宋体;color:black">或安全的信息、防碍互联网运行安全的信息；侵害他人合法权益的信息和</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >/</span><span style="font-size:9.5pt;font-family:宋体;color:black">或其他有损于社会秩序、社会治安、公共道德的信息或内容、任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料等，且不得为他人实施上述行为提供任何便利；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >6</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）以任何技术手段或其他方式干扰平台的正常运行或干扰其他用户对平台服务的使用；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >7</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）通过任何方式搜集本平台服务中其他用户的用户名、电子邮件等相关信息，并以发送垃圾邮件、连锁邮件、垃圾短信、即时消息等方式干扰、骚扰其他用户；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >8</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）从事其他违反法律法规、政策及公序良俗、社会公德等的行为或对互联网的正常运转造成不利影响的行为。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.7 </span><span style="font-size:9.5pt;font-family:宋体;color:black">本平台服务仅供您个人使用，除非经本平台书面许可，您不得进行以下行为：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >1</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）通过本平台发布包含广告、宣传、促销等内容的信息；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >2</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）将本平台提供的服务再次许可他人使用；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >3</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）其他超出本平台正常服务功能之外的行为。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.8 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您充分了解并同意，您必须为自己注册账号下的一切行为负责，包括您所发表的任何内容、您进行的各项操作、您进行的授权以及由此产生的任何后果。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.9 </span><span style="font-size:9.5pt;font-family:宋体;color:black">如果本平台发现或收到他人举报您发布的信息违反前述约定或本协议其他约定的，本平台有权进行独立判断并采取技术手段予以删除、屏蔽或断开相关的信息链接。同时，本平台有权视您的行为性质，采取包括但不限于暂停或终止本服务、追究法律责任等措施。本平台有权判断用户的行为是否符合平台服务条款规定的权利，如果本平台认为用户违背了本协议、法律法规政策等规定，本平台有权随时变更、中断或终止部分或全部服务的权利，且无需事先告知。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >六、知识产权声明</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >6.1 </span><span style="font-size:9.5pt;font-family:宋体;color:black">本平台在服务中提供的任何软件、网站、商业标识、版面设计、排版方式、文字、图片、图形、音频、视频、有关数据等内容、功能和服务的著作权、商标权、专利权、商业秘密等知识产权均受中华人民共和国法律法规和相应的国际条约保护，本平台所有者或技术支持方享有上述知识产权，但相关权利人依照法律规定应享有的权利除外。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >6.2 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您在使用本平台的过程中，可能会使用到由</span><span
        style="font-size:9.5pt;font-family:宋体;color:red"
      >第三方开</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >发的在本平台运行的功能、软件或服务，用户除遵守本协议相关规定以外，还应遵守第三方相关规定，并尊重第三方权利人对其功能、软件、服务及其所包含内容的相关权利。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >6.3 </span><span style="font-size:9.5pt;font-family:宋体;color:black">未经本平台或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >七、第三方提供的服务</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >7.1 </span><span style="font-size:9.5pt;font-family:宋体;color:black">本服务可能会使用第三方软件或技术，这种使用已经获得合法授权，您对本协议的签署及继续使用行为视为对接受第三方软件或技术服务的认可。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >7.2</span><span style="font-size:9.5pt;font-family:宋体;color:black">如因本平台使用的第三方软件或技术引发的任何纠纷，本平台将协助您与第三方协调解决，您使用本平台上提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的服务或用户协议。本平台和第三方之间对出现的纠纷在法律规定和约定的范围内各自承担责任。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >八、风险及免责</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >8.1 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您理解并同意：为了向您提供有效的服务，本服务会利用您终端设备的处理器和带宽等资源。在使用本平台过程中可能产生数据流量的费用，您需自行向运营商了解相关资费信息，并自行承担相关费用。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >8.2 </span></b><b><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >由于互联网本身所具有的不稳定性，本平台无法保证服务不会中断。系统因有关状况无法正常运作，使用户无法使用服务或使用服务受到影响时，本平台对用户或第三方不负任何责任，前述状况包括但不限于：</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >1</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）本平台系统停机维护、升级期间；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >2</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）电信设备出现故障不能进行数据传输时；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >3</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）由于黑客攻击、计算机病毒感染、木马或其他恶意程序破坏、网络供应商技术调整或故障、银行方面的问题等本平台不可抗拒因素造成的服务中断或延迟、信息丢失或泄漏等情形；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >4</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）用户的电脑软件、系统、硬件和通信线路出现故障；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >5</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）用户操作不当或通过非本平台授权的方式使用本服务；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >6</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）用户发布的内容被他人转发、分享，因此等传播可能带来的风险和责任；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >7</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）</span><span
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      /><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >由于网络信号不稳定、突发高并发等原因，所引起的登录失败、资料同步不完整、页面打开速度慢等情形；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >8</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成系统障碍不能执行业务的；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >9</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）</span><span
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      /><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >其他本平台无法控制或合理预见的情形。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >8.3 </span><span style="font-size:9.5pt;font-family:宋体;color:black">因您的过错导致的任何损失由您自行承担，该过错包括但不限于：不按照提示操作，未及时进行操作，遗忘或泄漏密码、校验码等，密码被他人破解，您使用的计算机或其他硬件、终端等被他人侵入或丢失，或您使用的软件被他人侵入，或者您的个人生物识别信息被他人利用。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >8.4 </span></b><b><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >您理解并同意，因业务发展需要，本平台有权在提前告知的前提下，对本平台的全部或部分服务内容进行变更、暂停、限制、终止或撤销，用户需承担此风险。</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >8.5 </span><span style="font-size:9.5pt;font-family:宋体;color:black">本平台会根据您选择的服务类型向您提供相应的服务。您理解并同意，本平台有权选择提供服务或开展合作的对象，有权决定功能开放、数据接口和相关数据披露的对象和范围，并有权视具体情况中止或终止向存有包括但不限于以下情形的用户提供本服务：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >1</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）违反法律法规或本协议规定的；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >2</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）影响使用者体验的；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >3</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）存在安全隐患的；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >（</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      >4</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >）违背本服务运营原则，或不符合本平台其他管理要求的。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >九、法律责任</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >9.1 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您理解并同意，本平台有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何人士采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >9.2 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生</span><span
        style="font-size:9.5pt;font-family:宋体;color:red"
      >第三方主</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >张的任何索赔、要求或损失，您应当独立承担责任；本平台因此遭受损失的，您也应当一并赔偿。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >9.3 </span></b><b><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >您理解并同意，您可向本平台进行的任何索赔均限于已经发生的直接损失。</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >十、</span></b><b><span
        style="font-size:12.0pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      /></b><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >协议的更新、变更</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >10.1 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >协议的更新</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >本协议内容同时包括本平台可能不定期发布的关于本服务的相关协议、服务声明、业务规则及公告指引等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，请同样遵守。否则，请立即停止使用本服务。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >10.2 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >协议的变更</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >您使用本服务即视为您已阅读并同意受本协议的约束。本平台有权在必要时修改本协议条款。您在享受各项服务时，可以在相关页面中查阅最新的协议条款。本协议条款变更后，如果您继续使用本服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用本服务，注销平台账号、停止使用本服务。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >10.3 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >协议的签署形式</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >如您对本协议条款内容有任何疑问，可向平台客服咨询。一旦点击</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >“</span><span style="font-size:9.5pt;font-family:宋体;color:black">同意</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >”</span><span style="font-size:9.5pt;font-family:宋体;color:black">或</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >“</span><span style="font-size:9.5pt;font-family:宋体;color:black">接受</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >”</span><span style="font-size:9.5pt;font-family:宋体;color:black">或</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >“</span><span style="font-size:9.5pt;font-family:宋体;color:black">注册</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >”</span><span style="font-size:9.5pt;font-family:宋体;color:black">按钮并成功注册，则与本平台之间形成协议关系，应当受本协议条款的约束。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >十一、</span></b><b><span
        style="font-size:12.0pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      /></b><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >法律适用及管辖</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >11.1 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >本协议的签订地为</span><span
        style="font-size:9.5pt;font-family:宋体;
color:red"
      >中国</span><span style="font-size:9.5pt;font-family:宋体;color:black">。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >11.2 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >本协议的订立、变更、执行和解释，以及与本协议有关的争议解决，均应适用中华人民共和国法律。如与本协议有关的某一特定事项没有法律规定或规定不明确，则应参照通用的国际商业惯例和行业惯例。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >11.3 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >如因本协议或本服务所引起或与其有关的任何争议应向</span><span
        style="font-size:9.5pt;
font-family:宋体;color:red"
      >广州仲裁委员会</span><span
        style="font-size:9.5pt;
font-family:宋体;color:black"
      >提起仲裁。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >十二、</span></b><b><span
        style="font-size:12.0pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      /></b><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >通知与送达</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >12.1 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >所有发给您的通知都可通过您在平台预留的通讯方式，以手机短信、电子邮件、常规的信件或在网站显著位置公告的等方式进行传送。平台将通过上述方法之一将消息传递给您，告知您服务条款的修改、服务变更、或其它重要事情。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >12.2 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >您应当保证和维持资料的有效性，您的资料若存在虚假、无效等任何可能导致您无法及时获取业务通知、客户服务、投诉处理、纠纷协调、技术支持等情况的，由您自行承担相应责任。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >12.3 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >本平台可以不定期以网页公告、电子邮件、手机短信、站内信、即时通讯工具等方式中的一种或多种向您发送与本服务有关的业务通知、服务提示、验证消息等各种业务通知。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >12.4 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >各方均应保证其联系方式真实、有效，通知自发送之日视为已送达收件人。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >十三、</span></b><b><span
        style="font-size:12.0pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      /></b><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >其他</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >13.1 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >本协议自您同意勾选并成功注册为本平台用户之日起生效，除非本平台终止本协议或者您丧失本平台用户资格，否则本协议始终有效。本协议终止并不免除用户根据本协议或其他有关协议、规则所应承担的义务和责任。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >13.2 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</span></p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

    </div>

  </section>
</template>

<script>
export default {
  name: 'Pingtaiyonghu',
  sassname:this.$store.state.user.domain.platformName
};
</script>

<style scoped>

</style>
