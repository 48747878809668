var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSeriesEmpty
    ? _c("h3", [_vm._v("暂无数据")])
    : _c("div", { staticClass: "chart" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }