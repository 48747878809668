var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dashboard" }, [
    _vm._m(0),
    _c("div", { staticClass: "content" }, [
      _c(
        "aside",
        { staticClass: "sidebar" },
        [
          _c("el-card", { staticClass: "card" }, [
            _c("h3", [_vm._v("今日新增数据")]),
            _c("p", [_vm._v("439.00 万元")]),
          ]),
          _c("el-card", { staticClass: "card" }, [
            _c("h3", [_vm._v("平台当前存款")]),
            _c("p", [_vm._v("439.00 万元")]),
          ]),
        ],
        1
      ),
      _c("main", { staticClass: "main" }, [
        _c("div", { ref: "chart", staticClass: "chart" }),
      ]),
      _c(
        "aside",
        { staticClass: "sidebar" },
        [
          _c("el-card", { staticClass: "card" }, [
            _c("h3", [_vm._v("平台企业数量")]),
            _c("p", [_vm._v("439")]),
          ]),
          _c("el-card", { staticClass: "card" }, [
            _c("h3", [_vm._v("平台总收入 IRR")]),
            _c("div", { ref: "lineChart", staticClass: "chart-small" }),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "header" }, [
      _c("h1", [_vm._v("数据可视化大屏")]),
      _c("div", { staticClass: "datetime" }, [_vm._v("14:54:45 | 2021.09.10")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }