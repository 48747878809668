<template>
    <div>
      <el-tabs :tab-position="tabPosition" style="height: 200px;">
        <template v-for="i in sasscompany">
          <el-tab-pane :label="i.name">
            <template>
              <el-tab-pane label="权限管理">权限管理</el-tab-pane>
              <el-tab-pane label="角色管理">角色管理</el-tab-pane>
            </template>
          </el-tab-pane>
        </template>
       
      </el-tabs>
    </div>
    </template>
    <script>
    export default {
      data() {
        return {
          tabPosition: 'left',
          
        }
      }
    }
    </script>
    <style>
    </style>